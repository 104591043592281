/* sourcecode for loading screen: https://codepen.io/jeerapabeam/pen/VNBvjv used under MIT liscense */
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    margin: -1px !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}
#wrapper {
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($color: #666666, $alpha: 0.7);
}

#loading-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000;
    width: 100px;
    height: 30px;
    margin: 0 0 0 -40px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 30px;
}

#loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 200px;
    height: 200px;
    margin: -85px 0 0 -85px;
}

#loading-content:after {
    content: '';
    position: absolute;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
}

#loading-content:before {
    content: '';
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
}

#loading-content {
    border: 3px solid transparent;
    border-top-color: #e07300;
    border-bottom-color: #ff890d;
    border-radius: 50%;
    animation: loader 3s linear infinite;
}

#loading-content:before {
    border: 3px solid transparent;
    border-top-color: #0f87af;
    border-bottom-color: #3196b8;
    border-radius: 50%;
    animation: loader 4s linear infinite;
}

#loading-content:after {
    border: 3px solid transparent;
    border-top-color: #e07300;
    border-bottom-color: #ff890d;
    border-radius: 50%;
    animation: loader 4.5s linear infinite;
}